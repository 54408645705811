import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

import {loadAndUnloadArchivable} from "@/data/load_and_unload_archivable.js";
import {ActionsTile} from "@/library/controls/actions_tile.jsx";
import {SettingsTile} from "@/library/controls/settings_tile.jsx";
import {SettingToggleCheckbox} from "@/library/controls/setting_toggle_checkbox.jsx";
import {selectApplyCommon} from "@/data/select_apply_common.js";
import {selectAppSetting} from "@/data/preferences/select_app_setting.js";

import {SimulatorCard} from "./simulator_card.jsx";
import {SimulatorCardNew} from "./simulator_card_new.jsx";
import {SimulatorErrorBoundary} from "./simulator_error_boundary.jsx";
import {SettingToggleButton} from "@/library/controls/setting_toggle_button.jsx";

export function SimulatorsPane() {
  const dispatch = useDispatch();
  const intl = useIntl();

  const selectIncludeArchived = selectAppSetting("operations.simulators.includeArchived");
  const selectFilterArchived = createSelector(selectIncludeArchived, ia => !ia);
  const selectSimulators = selectApplyCommon("simulator", selectFilterArchived);

  const simulators = useSelector(selectSimulators);
  const includeArchived = useSelector(selectIncludeArchived);
  const sessions = useSelector(s => s.environmentSession);
  const showNewCardAlways = useSelector(selectAppSetting("operations.simulators.showNewCardAlways"));
  const showNewCardOnce = useSelector(selectAppSetting("operations.simulators.showNewCardOnce"));

  const showNewCard = showNewCardAlways || showNewCardOnce;

  useEffect(
    loadAndUnloadArchivable("Simulator", dispatch, sessions, includeArchived),
    [includeArchived]
  );

  return (
    <div className="simulators-pane pane focusable-collection">
      <div className="pane-top">
        <h1 className="simulators-title pane-title">
          {intl.formatMessage({id: "operations.simulators.title"})}
        </h1>
        <ActionsTile pane="operations.simulators" className="pane-actions">
          {!showNewCardAlways && (
            <SettingToggleButton className="show-new-card-once" setting="showNewCardOnce"/>
          )}
        </ActionsTile>
        <SettingsTile pane="operations.simulators">
          <SettingToggleCheckbox setting="includeArchived"/>
          <SettingToggleCheckbox setting="showNewCardAlways"/>
        </SettingsTile>
      </div>
      <div className="simulators-list">
        {showNewCard && (
          <SimulatorCardNew/>
        )}
        {simulators.map(simulator => (
          <SimulatorErrorBoundary simulator={simulator} key={simulator.id}>
            <SimulatorCard
              simulator={simulator}
              session={simulator.session}
            />
          </SimulatorErrorBoundary>
        ))}
      </div>
    </div>
  );
}
